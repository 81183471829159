<template>
    <div class="system-dashboard min-h-100vh d-flex justify-content-center">
        <app-loader v-if="loadingStatus"></app-loader>
        <div v-if="!loadingStatus" class="system-dashboard-box width-872 radius-8">
            <div class="d-flex justify-content-between align-items-center flex-row">
                <img @click="navigateToHome" src="../../../assets/images/logo-blue.png" alt="Logo">
                <div class="avater">
                    <img class="size-40-fit" v-if="user" :src="user" alt="Logo">
                    <avatar :rounded="'full'" v-if="!user" :type="'icon'" style="margin-right: 5px">
                        {{ generateNameInitials }}
                    </avatar>
                </div>
            </div>
            <div class="d-flex justify-content-center align-items-center h-100">
                <div v-if="errorCode" class="text-center">
                    <h1 class="font-80 color-blue mb-2">{{ errorCode }}</h1>
                    <h5 class="mb-3">Your request for this page has been denied bacause of access control.</h5>
                    <!-- <p class="mb-5">
                        You tried to visit : <span class="text-primary">{{ this.previousPath }}</span>
                    </p> -->
                    <kbutton @click="navigateToHome" :theme-color="'primary'" :class="'w-20'" type="Button">Go Home
                    </kbutton>
                </div>
                <div v-else class="text-center">
                    <h2 class="mb-3">Page Not Found</h2>
                    <kbutton @click="navigateToHome" :theme-color="'primary'" :class="'w-20'" type="Button">Go Home
                    </kbutton>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Avatar } from "@progress/kendo-vue-layout";
import AppLoader from "../AppLoader/AppLoader.vue";


export default {
    components: {
        kbutton: Button,
        avatar: Avatar,
        'app-loader': AppLoader
    },
    props: {
    },
    data() {
        return {
            errorCode: "",
            previousPath: "",
        };
    },
    created() {
        this.$store.dispatch("getUser");
        this.errorCode = this.$route.query.error;
        this.previousPath = window.location.origin + window.history?.state?.back
        this.$store.commit("SET_PAGE_NOT_FOUND_VISIBILITY", true);
    },
    unmounted() {
        this.$store.commit("SET_PAGE_NOT_FOUND_VISIBILITY", false);
    },
    methods: {
        navigateToHome() {
            this.$router.replace("/");
        },
    },
    computed: {
        user() {
            return this.$store.getters.userDetails.userPhotoPath;
        },
        userDetails() {
            const data = [1, 2, 3]
            //delete 0 index data
            data.splice(0, 1)
            return this.$store.getters.userDetails;
        },
        loadingStatus() {
            return this.$store.getters.loadingStatus;
        },
        generateNameInitials() {
            if (this?.userDetails?.firstName && this?.userDetails?.lastName) {
                return (
                    this?.userDetails?.firstName?.charAt(0) +
                    this?.userDetails?.lastName?.charAt(0)
                );
            } else if (this?.userDetails?.firstName) {
                return this?.userDetails?.firstName?.charAt(0);
            } else if (this?.userDetails?.lastName) {
                return this?.userDetails?.lastName?.charAt(0);
            } else {
                return "NA";
            }
        },
    },
};
</script>
<style type="text/css"></style>
  